<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b mb-4"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Stok Obat</b> yang ada di Klinik Hayandra
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/medicine/maintain-stock-add')"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <!-- card filter -->
        <div class="card mb-4">
          <div class="card-body p-5">
            <!-- Filter -->
            <div class="row justify-content-end">
              <div class="col-md-9">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>
              <div class="col-md-3 col-12">
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="filter.medicine_name"
                    placeholder="Saring Nama Obat"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row mt-3">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="periodFilterOnClick" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="periodFilterOnClick"
                            variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- table medicine stock -->
          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-lg-6">
                    Keterangan :
                    <i class="fas fa-arrow-down" style="color: green"></i>
                    <span class="font-weight-bold">(Stok Masuk)</span>,
                    <i class="fas fa-arrow-up" style="color: red"></i>
                    <span class="font-weight-bold">(Stok Keluar)</span>
                  </div>
                  <div
                    class="col-md-6 col-sm-6 col-lg-6 d-flex justify-content-end"
                  >
                    <vue-excel-xlsx
                      :data="items"
                      :columns="columns"
                      :file-name="'Stok Obat'"
                      :file-type="'xlsx'"
                      :sheet-name="'sheet 1'"
                    >
                      <b-button
                        class="sm mr-2"
                        style="background-color: #016e38"
                        ><i
                          class="fas fa-file-excel px-0"
                          style="color: #ffffff"
                        ></i
                      ></b-button>
                    </vue-excel-xlsx>
                  </div>
                </div>

                <!-- Table -->
                <b-table
                  striped
                  hover
                  class="mt-3"
                  :items="items"
                  :fields="fields"
                  responsive="sm"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '5%' : '' }"
                    />
                  </template>
                  <template #cell(stock_in_out)="data">
                    <div class="text-center">
                      <i
                        v-if="data.item.stock_in_out == 0"
                        class="fas fa-arrow-down"
                        style="color: green"
                        v-b-tooltip.hover
                        title="Stok Masuk"
                      ></i>
                      <i
                        v-if="data.item.stock_in_out == 1"
                        class="fas fa-arrow-up"
                        style="color: red"
                        v-b-tooltip.hover
                        title="Stok Keluar"
                      ></i>
                    </div>
                  </template>
                  <template #cell(price)="data">
                    <div class="text-right">
                      <span v-if="data.item.price == 0.0"></span>
                      <span v-if="data.item.price != 0.0">{{
                        parseInt(data.item.price).toLocaleString("id-ID")
                      }}</span>
                    </div>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="m-1 btn-info"
                      v-b-tooltip.hover
                      title="Detail"
                      @click="
                        $router.push({
                          path:
                            '/medicine/maintain-stock-detail/' + data.item.id,
                        })
                      "
                      ><i class="fas fa-eye px-0"></i
                    ></b-button>

                    <b-button
                      size="sm"
                      class="m-1"
                      variant="primary"
                      v-b-tooltip.hover
                      title="Lihat Batch"
                      v-if="data.item.stock_in_out == 0"
                      @click="
                        $router.push({
                          path:
                            '/medicine/batch-stock-per-batch/' + data.item.id,
                        })
                      "
                      ><i class="fas fa-boxes px-0"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="items.length != 0"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @page-click="pageOnClick"
                  class="mt-4"
                ></b-pagination>
              </div>
            </div>
          </div>

          <!-- table medicine stock summary -->
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-end">
                  <b-button
                    @click="btnExcelSumOnClick"
                    style="background-color: #016e38"
                    ><i
                      class="fas fa-file-excel px-0"
                      style="color: #ffffff"
                    ></i
                  ></b-button>
                </div>

                <b-table
                  striped
                  hover
                  class="mt-3"
                  :items="items_summary"
                  :fields="field_summary"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                </b-table>
                <b-pagination
                  v-if="items_summary.length != 0"
                  v-model="currentPageSum"
                  :total-rows="totalRowsSum"
                  :per-page="perPageSum"
                  @page-click="pageOnClickSum"
                  class="mt-4"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Batch History -->
    <b-modal ref="my-modal" hide-footer title="Riwayat Batch" size="xl">
      <b-table
        striped
        hover
        class="mt-3"
        :items="items_history"
        :fields="fields_history"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '20%' : '' }"
          />
        </template>
        <template #cell(stock_in_out)="data">
          <i
            v-if="data.item.stock_in_out == 0"
            class="fas fa-arrow-down"
            style="color: green"
            v-b-tooltip.hover
            title="Stok Masuk"
          ></i>
          <i
            v-if="data.item.stock_in_out == 1"
            class="fas fa-arrow-up"
            style="color: red"
            v-b-tooltip.hover
            title="Stok Keluar"
          ></i>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debounce } from "@/core/modules/Helper.js";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import XLSX from "xlsx";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        medicine_name: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },

      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },

      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,

      // Pagination summary
      perPageSum: 40,
      currentPageSum: 1,
      totalRowsSum: 1,

      // manipulate btn
      manipulateBtn: false,

      // Table medicine stock Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "medicine_name",
          label: "Obat",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "stock_in_out",
          label: "Jenis",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "quantity",
          label: "Jml",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "remaining_stock",
          label: "Stok sisa",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        // {
        //   key: "vendor_name",
        //   label: "Penyedia",
        //   sortable: true,
        //   thClass: "th-list-medicines",
        //   tdClass: "td-list-medicines",
        // },
        {
          key: "created_at_display",
          label: "Tgl. Input",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "notes",
          label: "Sumber - Pasien - Pegawai",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        { key: "actions", label: "Aksi" },
      ],

      // items medicine stock
      items: [],

      // table head excel export medicine stock
      columns: [
        {
          field: "number",
          label: "#",
        },
        {
          field: "medicine_name",
          label: "Obat",
        },
        {
          field: "stock_in_out_display",
          label: "Jenis",
        },
        {
          field: "quantity",
          label: "Jml",
        },
        {
          field: "remaining_stock",
          label: "Stok sisa",
        },
        {
          field: "price",
          label: "Harga",
        },
        // {
        //   field: "vendor_name",
        //   label: "Penyedia",
        // },
        {
          field: "created_at_display",
          label: "Tgl. Input",
        },
        {
          field: "notes",
          label: "Sumber - Pasien - Pegawai",
        },
      ],

      // Table item modal history stock
      items_history: [],

      // Table Head modal history stock
      fields_history: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "stock_in_out",
          label: "Jenis Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "medicine_name",
          label: "Obat",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "batch_no",
          label: "Batch No",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "quantity",
          label: "Kuantitas",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "remaining_stock",
          label: "Sisa Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        // { key: "actions", label: "Aksi" },
      ],

      // items summary
      items_summary: [],

      // Table Head modal history stock
      field_summary: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "medicine_name",
          label: "Nama Obat",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "quantity",
          label: "Jml",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
      ],

      // items excel
      excelItems: [],
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    pageOnClickSum(evt, page) {
      evt.preventDefault();
      this.currentPageSum = page;
      this.paginationSummary();
    },

    // period filter
    periodFilterOnClick() {
      this.pagination()
      this.paginationSummary()
    },

    async pagination() {
      let filterParams = `&medicine_name=${this.filter.medicine_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "medicine-stocks",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async paginationSummary() {
      let filterParams = `&medicine_name=${this.filter.medicine_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "medicine-summary-stocks",
        `?page=${this.currentPageSum}&page_size=${this.perPageSum}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRowsSum = pagination.total;

      let a, b;
      b = this.perPageSum * (this.currentPageSum - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items_summary = response.data;
    },

    filterByName() {
      debounce(() => {
        this.pagination();
        this.paginationSummary();
      }, 500);
    },

    showModal(id) {
      this.$refs["my-modal"].show();
      this.paginationHistory(id);
    },

    async paginationHistory(id) {
      let response = await module.paginate(
        "medicine-batch-histories/get-by-medicine-id/" + id
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items_history = response.data;
    },

    async deleteData(id, medicine_id, vendor_id) {
      // Delete Data
      let result = await module.delete(
        "medicine-stocks/" + id + "/" + medicine_id + "/" + vendor_id
      );
      if (result) {
        console.log(result, medicine_id, vendor_id);
        this.pagination();
      }
    },

    async getMedicineHistory() {
      let history = await module.get("medicine-stocks-history/" + 2);
      console.log("history", history);
      // If Data Not Found
      // if (this.history == null) {
      //   // Redirect To List
      //   this.$router.push('/medicine/maintain-stock')
      // }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        // console.log("looping")

        if (access_right[a] == "9005") {
          this.manipulateBtn = true;
        }
      }
    },

    // filter
    resetFilter() {
      this.filter = validation.clearForm(this.filter);
      this.filter.start_date = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.pagination();
      this.paginationSummary();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();
        this.paginationSummary();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();
        this.paginationSummary();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();
        this.paginationSummary();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();
        this.paginationSummary();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async btnExcelSumOnClick() {
      SwalLoading.fire();
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&diagnose_id=${this.filter.diagnose_id}&patient_name=${this.filter.patient_name}`;
      let response = await module.paginate(
        "medicine-summary-stocks",
        `?page_size=all${filterParams}`
      );

      this.excelItems = response.data;
      SwalLoading.close();

      const data = XLSX.utils.json_to_sheet(this.excelItems);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(
        wb,
        "Daftar Ringkasan Pemakaian Obat & Alkes (Periode " +
          moment(this.filter.start_date).format("DD-MM-YYYY") +
          " sampai " +
          moment(this.filter.end_date).format("DD-MM-YYYY") +
          ").xlsx"
      );
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Obat", route: "/medicine/list" },
      { title: "Stok Obat" },
    ]);
    this.pagination();
    this.paginationSummary();
    this.getMedicineHistory();
    this.setActiveMenu();
  },
};
</script>

<style scoped>
.th-list-medicines {
  vertical-align: middle !important;
}

.td-list-medicines {
  vertical-align: middle !important;
}
</style>